import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import GererParamNotePratique from "./GererParamNotePratique";
import { faHSquare } from "@fortawesome/free-solid-svg-icons";
import GererNote from "./GererNote";
import { paramexo, paramprat, paramtheo } from "../../../service/config";
import { generateRandomString } from "../../../service/RandomString";

export default function GererParamNotes() {
  const [activeKey, setActiveKey] = useState("pratique");

  const handleSelect = (key) => {
    console.log(`Active tab changed to: ${key}`);
    setActiveKey(key); // Met à jour l'onglet actif si nécessaire
  };

  return (
    <div>
      <div className="container-lg container-fluid pt-4">
        <Card className="p-3">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="">
              <h2>
                <FontAwesomeIcon icon={faHSquare} className="me-1" /> Gérer
                total questionnaire
              </h2>
            </div>
            <div style={{ width: "45%" }}>
              <Tabs
                defaultActiveKey="pratique"
                onSelect={handleSelect}
                id="justify-tab-example"
                className="mb-3"
                justify
              >
                <Tab
                  eventKey="pratique"
                  className="text-nowrap w-100"
                  title="Examen pratique"
                ></Tab>
                <Tab eventKey="theorique" title="Examen Theorique"></Tab>
                <Tab eventKey="exo" title="Exercice"></Tab>
              </Tabs>
            </div>
          </div>
        </Card>
      </div>
      <div>
        {activeKey == "pratique" ? (
          <GererNote
            key={generateRandomString(6)}
            id={"id_pnp"}
            titre={"Examen pratique"}
            urlAPI={paramprat}
          />
        ) : activeKey == "theorique" ? (
          <GererNote
            key={generateRandomString(7)}
            id={"id_pnt"}
            titre={"Examen theorique"}
            urlAPI={paramtheo}
          />
        ) : activeKey == "exo" ? (
          <GererNote
            key={generateRandomString(5)}
            id={"id_pne"}
            titre={"Exercices"}
            urlAPI={paramexo}
          />
        ) : null}
      </div>
    </div>
  );
}

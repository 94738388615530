import {
  faAdd,
  faCancel,
  faEdit,
  faEye,
  faFile,
  faSchool,
  faStepBackward,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../outils/Pagination";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  FichierLesson_API,
  file_url,
  LESSON_API,
  moduleByLesson,
  MODULE_API,
  Questionnaire_API,
  Fichierq_API,
  Reponse_API,
  Questionnaire_API_V2,
} from "../../../service/config";
import axios from "axios";
import { toast } from "react-toastify";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modifquestionnaire from "./Modifquestionnaire";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Langue } from "../../../outils/Langueopt";
import { useCategoryOptions } from "../../../outils/Categorieopt";
import { handleChoiceChange } from "../../../outils/Changeoptcateg";
import { key } from "localforage";
import SelectFinal from "../../../service/Select/selectFinal";

const animatedComponents = makeAnimated();

export default function GererQuestionnaire() {
  let cancelTokenSource = React.useRef(null);
  const categoptions = useCategoryOptions();
  const [lg, setLg] = useState([]);
  const editorConfig = {
    toolbar: "",
  };

  const [eventy, setEventy] = useState("");
  const dispatch = useDispatch();
  const dataRedux = useSelector((state) => state.exercice);

  const columns = [
    // {
    //   name: "ID",
    //   width:'7%',
    //   selector: (row) => row.id_ques,
    //   sortable: true,
    // },
    {
      name: "Question",
      wrap: true,
      width: "18%",
      selector: (row) => (
        <div className="non-edit">
          <CKEditor
            config={editorConfig}
            editor={Editor}
            data={row.qcm_ques}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
              editor.enableReadOnlyMode("editor");
              const toolbar = editor.ui.view.toolbar.element;
              toolbar.style.display = "none";
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
            }}
            // onBlur={ ( event, editor ) => {
            //     console.log( 'Blur.', editor );
            // } }
            // onFocus={ ( event, editor ) => {
            //     console.log( 'Focus.', editor );
            // } }
          />
        </div>
      ),
    },
    {
      center: true,
      width: "12%",
      name: "Durée (en seconde)",
      format: (row) => <span className="badge bg-info">{row.dure_ques}</span>,
      selector: (row) => row.dure_ques,
      sortable: true,
      wrap: true,
    },
    {
      width: "14%",
      name: "Module",
      wrap: true,
      selector: (row) => labelMod(row),
      sortable: true,
      wrap: true,
    },
    {
     
      name: "Langue",
      wrap: true,
      selector: (row) => row.lang_ques || "à speficier",
      sortable: true,
      wrap: true,
    },
    {
     
      name: "Catégories",
      wrap: true,
      selector: (row) =>row?.categories?.length > 0 ?  <div className="d-flex gap-2 flex-wrap">
      {row?.categories?.map((el,i)=> <span key={i} className="badge bg-cyan text-wrap">{el.categ}</span>)}
      </div> : "à specifier" ,
      sortable: true,
      wrap: true,
    },
    {
      center: true,
      name: "Réponses",
      selector: (row) =>
        row.val_ques ? (
          <>{row.val_ques}</>
        ) : (
          <div className="d-flex">
            <Link to={`/admin/reponses/${row.id_ques}`} className="nav-link ">
              <span className="badge bg-info py-2 px-2 mx-2">
                <FontAwesomeIcon icon={faFile} className="me-2" />
                Texte
              </span>
            </Link>
            <Link
              to={`/admin/reponsesFichier/${row.id_ques}`}
              className="nav-link "
            >
              <span className="badge bg-info py-2 px-2 mx-2">
                <FontAwesomeIcon icon={faFile} className="me-2" />
                image
              </span>
            </Link>
          </div>
        ),
      sortable: true,
      wrap: true,
      width: "22%",
    },
    // {
    //   name: "Trier date d'ajout",
    //   wrap:true,
    //   format:(row)=>moment(row.created_at).format("DD/MM/yy"),
    //   selector: (row) =>row.created_at,
    //   sortable: true,
    //   wrap:true
    // },
    {
      width: "12%",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-1 justify-content-center">
          <Modifquestionnaire
            setidLF={setidLF}
            setEventy={setEventy}
            exo={row}
            optionmod={options}
            setLoad={setLoad}
            type={row.val_ques ? "reponse" : "choix"}
          />
          <button
            type="button"
            onClick={() => setID(row.id_ques)}
            data-bs-toggle="modal"
            data-bs-target="#modal-danger"
            className="btn btn-danger"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [search, setsearch] = useState("");
  const ref = useRef(null);
  const [ID, setID] = useState(null);
  const [loadModule, setLoadModule] = useState(false);
  const [choix, setchoix] = useState(null);
  let dataLes = [];
  const [idLF, setidLF] = useState(null);
  const [load, setLoad] = useState(false);
  const [barcolor, setBarcolor] = useState("blue");

  const [error, setError] = useState([]);
  const [options, setOptions] = useState([]);
  const [questionnaire, setquestionnaire] = useState([]);
  const [affichageRep, setaffichageRep] = useState(null);
  const [question, setQuestion] = useState({
    qcm_ques: "",
    dure_ques: null,
  });
  const vide = {
    qcm_ques: "",
    dure_ques: null,
    id_mod: "",
  };
  const [formValues, setFormValues] = useState([]);
  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setQuestion({ ...question, [name]: value });
  };

  const [image, setImage] = useState(null);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setQuestion({ ...question, img_les: e.target.files[0] });
    }
  };
  const onChangefichier = (i, e) => {
    let formfichier = [...formValues];

    if (e.target.files && e.target.files[0]) {
      formfichier[i]["fichierq"] = e.target.files[0];
    }
    setFormValues(formfichier);

    if (e.target.name === "fichierq") {
      for (let index = 0; index < formValues.length; index++) {
        const element = formValues[index];
        if (index === i) {
          document.getElementById(`fichierq${index}`).innerHTML = "";
          if (
            element.fichierq === "" ||
            element.fichierq === null ||
            element.fichierq === {}
          ) {
            document.getElementById(`fichierq${index}`).innerHTML =
              "<p className='text-danger fs-5'>fichier reponse requis</p>";
          } else {
            document.getElementById(`fichierq${index}`).innerHTML = "";
          }
        }
      }
    }
  };
  function truncate(str) {
    if (str) {
      return str.length > 10 ? str.substring(0, 150) + "..." : str;
    }
  }
  let handleChangeFichier = (i, e) => {
    let formfichier = [...formValues];

    formfichier[i][e.target.name] = e.target.value;
    formfichier[i]["index"] = i;

    setFormValues(formfichier);
    if (affichageRep === "texte") {
      if (e.target.name === "type_rep") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`type_rep${index}`).innerHTML = "";
            if (
              element.type_rep === "" ||
              element.type_rep === "choisir ici..."
            ) {
              document.getElementById(`type_rep${index}`).innerHTML =
                "<p className='text-danger fs-5'>type reponse requis</p>";
            } else {
              document.getElementById(`type_rep${index}`).innerHTML = "";
            }
          }
        }
      }
      if (e.target.name === "desc_rep") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`desc_rep${index}`).innerHTML = "";
            if (element.desc_rep === "") {
              document.getElementById(`desc_rep${index}`).innerHTML =
                "<p className='text-danger fs-5'>Description requis</p>";
            } else {
              document.getElementById(`desc_rep${index}`).innerHTML = "";
            }
          }
        }
      }
    } else if (affichageRep === "image") {
      if (e.target.name === "type_repq") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`type_repq${index}`).innerHTML = "";
            if (
              element.type_repq === "" ||
              element.type_repq === "choisir ici..."
            ) {
              document.getElementById(`type_repq${index}`).innerHTML =
                "<p className='text-danger fs-5'>type reponse requis</p>";
            } else {
              document.getElementById(`type_repq${index}`).innerHTML = "";
            }
          }
        }
      }

      if (e.target.name === "desc_repq") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`desc_repq${index}`).innerHTML = "";
            if (element.desc_repq === "") {
              document.getElementById(`desc_repq${index}`).innerHTML =
                "<p className='text-danger fs-5'>Description requis</p>";
            } else {
              document.getElementById(`desc_repq${index}`).innerHTML = "";
            }
          }
        }
      }
      if (e.target.name === "type_fichq") {
        for (let index = 0; index < formValues.length; index++) {
          const element = formValues[index];
          if (index === i) {
            document.getElementById(`type_fichq${index}`).innerHTML = "";
            if (element.type_fichq === "") {
              document.getElementById(`type_fichq${index}`).innerHTML =
                "<p className='text-danger fs-5'>Type fichier requis</p>";
            } else {
              document.getElementById(`type_fichq${index}`).innerHTML = "";
            }
          }
        }
      }
    }
  };

  const addInput = (e) => {
    e.preventDefault();
    const nvInput = [...formValues, { id_ques: idLF, type_fichq: "Photo" }];
    setFormValues(nvInput);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [ito, setIto] = useState([]);
  const change = (choice) => {
    setIto(choice);
    setQuestion({ ...question, id_mod: choice.value });
  };

  const ajoutLessonFichier = (e) => {
    e.preventDefault();
    var process = 0;
    setLoad(true);

    if (affichageRep === "texte") {
      for (let index = 0; index < formValues.length; index++) {
        const element = formValues[index];
        if (
          element.type_rep === undefined ||
          element.type_rep === "" ||
          element.type_rep === "choisir ici..."
        ) {
          document.getElementById(`type_rep${index}`).innerHTML =
            "<p className='text-danger fs-5'>type reponse requis</p>";
          setLoad(false);
          break;
        }
        if (
          element.desc_rep === undefined ||
          element.desc_rep === "" ||
          element.desc_rep === "choisir ici..."
        ) {
          document.getElementById(`desc_rep${index}`).innerHTML =
            "<p className='text-danger fs-5'>Description reponse requis</p>";
          setLoad(false);
          break;
        }
        if (index === formValues.length - 1) {
          formValues.forEach((element) => {
            process++;
            try {
              axios
                .post(Reponse_API, element, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                  },
                })
                .then((response) => {
                  console.log(response);
                  if (process === formValues.length) {
                    setLoad(false);
                    toast.success("Réponse ajoutée");
                    document.getElementById("fermerModal").click();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  const msg = error.response.data;
                  console.log(msg);
                  if (process === formValues.length) {
                    setLoad(false);
                    toast.error(JSON.stringify(msg));
                  }
                });
              setError([]);
            } catch (error) {
              console.log(error);
            }
          });
        }
      }
    } else {
      for (let index = 0; index < formValues.length; index++) {
        const element = formValues[index];
        if (
          element.type_repq === undefined ||
          element.type_repq === "" ||
          element.type_repq === "choisir ici..."
        ) {
          document.getElementById(`type_repq${index}`).innerHTML =
            "<p className='text-danger fs-5'>type réponse requis</p>";
          setLoad(false);
          break;
        }

        if (
          element.fichierq === undefined ||
          element.fichierq === "" ||
          element.fichierq === null ||
          element.fichierq === {}
        ) {
          document.getElementById(`fichierq${index}`).innerHTML =
            "<p className='text-danger fs-5'>fichier reponse requis</p>";
          setLoad(false);
          break;
        }
        if (
          element.desc_repq === undefined ||
          element.desc_repq === "" ||
          element.desc_repq === "choisir ici..."
        ) {
          document.getElementById(`desc_repq${index}`).innerHTML =
            "<p className='text-danger fs-5'>Description reponse requis</p>";
          setLoad(false);
          break;
        }
        if (
          element.type_fichq === undefined ||
          element.type_fichq === "" ||
          element.type_fichq === "choisir ici..."
        ) {
          document.getElementById(`type_fichq${index}`).innerHTML =
            "<p className='text-danger fs-5'>Type fichier reponse requis</p>";
          setLoad(false);
          break;
        }

        if (index === formValues.length - 1) {
          formValues.forEach((element) => {
            process++;
            try {
              axios
                .post(Fichierq_API + "?_method=PUT", element, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                  },
                })
                .then((response) => {
                  console.log(response);
                  if (process === formValues.length) {
                    setLoad(false);
                    toast.success("Réponse ajoutée");
                    document.getElementById("fermerModal").click();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  const msg = error.response.data;
                  console.log(msg);
                  if (process === formValues.length) {
                    setLoad(false);
                    toast.error(JSON.stringify(msg));
                  }
                });
              setError([]);
            } catch (error) {
              console.log(error);
            }
          });
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      question.qcm_ques === "" ||
      question.qcm_ques === undefined ||
      question.qcm_ques === null
    ) {
      setError({ ...error, qcm_ques: "ce champs est requis" });
    } else if (
      (choix === "qcm" && affichageRep == null) ||
      (choix === "qcm" && affichageRep === "choisir ici...")
    ) {
      toast.info("veuillez selection le type de reponse du questionnaire");
    } else {
      console.log(formValues);
      console.log(question);
      setLoad(true);
      const apiErrors = {};

      try {
        await axios
          .post(Questionnaire_API, question, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            toast.success("insertion reussi");
            getAllQuestionnaire(pagination.limit, pagination.offset);
            let id_ques = response.data.data.id_ques;
            setidLF(id_ques);
            if (choix === "question") {
              document.getElementById("fermerModal").click();
            }
            setQuestion(vide);
            setLoad(false);

            console.log(response);
            console.log(id_ques);
          })
          .catch((error) => {
            console.log(error);
            const msg = error.response.data;
            setLoad(false);
            if (msg) {
              apiErrors.qcm_ques = msg.qcm_ques;
              apiErrors.dure_ques = msg.dure_ques;
              apiErrors.id_mod = msg.id_mod;
              apiErrors.lang_ques = msg.lang_ques
              apiErrors.id_categ = msg.id_categ
            }
            if (error.response.data.message) {
              toast.error(truncate(error.response.data.message));
            }

            console.log(error);
          });
        setError(apiErrors);
        setLoad(false);
      } catch (error) {
        toast.error("probleme de connexion au serveur !");
        console.log(error);
        setLoad(false);
      }
    }
  };
  const GetModuleByoneLesson = async (module) => {
    const GETIO = [];
    try {
      var i = 0;
      module.forEach((element) => {
        i++;
        GETIO.push({ value: element.id_mod, label: element.titre_mod });
        if (i === module.length) {
          setIto(GETIO);
          change(GETIO);
        }
      });
    } catch (error) {}
  };

  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  // Configuration initiale de la pagination
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  // Charger les données de l'API

  // Gérer les changements de pagination
  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      offset: (page - 1) * prev.limit, // Calculer le nouvel offset
    }));
  };

  const handlePerRowsChange = (newLimit, page) => {
    setPagination({
      limit: newLimit,
      offset: (page - 1) * newLimit,
    });
  };

  const getAllQuestionnaire = async (limit, offset) => {
    ref.current.continuousStart();
    setBarcolor("blue");
    setquestionnaire(dataRedux);

    // Si une requête précédente est en cours, l'annuler
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Requête annulée en raison d'une nouvelle requête."
      );
    }

    // Créer un nouveau CancelToken
    cancelTokenSource.current = axios.CancelToken.source();
    try {
      await axios
        .get(`${Questionnaire_API_V2}?limit=${limit}&offset=${offset}`, {
          cancelToken: cancelTokenSource.current.token,
        })
        .then((response) => {
          console.log(response);
          const data = response.data.data;
          setTotalRows(parseInt(response.data.meta.total, 10));
          setquestionnaire(data);
          dispatch({ type: "addexo", payload: data });
          ref.current.complete();
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log(error);
          } else {
            setBarcolor("red");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllModule = async () => {
    setBarcolor("blue");
    const getModules = [];
    try {
      await axios
        .get(MODULE_API)
        .then((response) => {
          console.log(response);
          const data = response.data.data;

          data.forEach((element) => {
            getModules.push({
              value: element.id_mod,
              label: element.titre_mod,
            });
          });
        })
        .catch((error) => {
          console.log(error);
          setBarcolor("red");
        });
      setOptions(getModules);
    } catch (error) {
      console.log(error);
    }
  };
  function comparer(a, b) {
    return new Date(a) - new Date(b);
  }
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoad(true);
    const apiErrors = {};
    try {
      await axios
        .post(Questionnaire_API + "/" + ID + "?_method=PUT", question, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          document.getElementById("fermermodif").click();
          toast.success("modification reussi");
          getAllQuestionnaire(pagination.limit, pagination.offset);
          setLoad(false);
          console.log(response);
        })
        .catch((error) => {
          toast.warn("un probleme est survenu");
          console.log(error);
          const msg = error.response.data;
          setLoad(false);
          if (msg) {
            apiErrors.qcm_ques = msg.qcm_ques;
            apiErrors.dure_ques = msg.dure_ques;
            apiErrors.id_mod = msg.id_mod;
            apiErrors.lang_ques = msg.lang_ques
            apiErrors.id_categ = msg.id_categ
          }

          console.log(error);
        });
      setError(apiErrors);
      setLoad(false);
    } catch (error) {
      toast.error("probleme de connexion au serveur !");
      console.log(error);
      setLoad(false);
    }
  };

  const supprimer = async () => {
    setLoad(true);
    try {
      await axios
        .delete(Questionnaire_API + "/" + ID)
        .then((response) => {
          toast.success("exercice numero " + ID + " supprimer");
          setLoad(false);
          document.getElementById("fermerdel").click();
          getAllQuestionnaire(pagination.limit, pagination.offset);
        })
        .catch((error) => {
          console.log(error);
          toast.error("module pas supprimer!");
          setLoad(false);
        });
    } catch (error) {
      toast.error("probleme interne");
      setLoad(false);
    }
  };

  const fetchUpdate = (lesson, module) => {
    setID(lesson.id_les);
    setQuestion(vide);
    setIto([]);
    GetModuleByoneLesson(module);
    const updt = {};
    setImage(file_url + lesson.img_les);
    updt.titre_les = lesson.titre_les;
    updt.desc_les = lesson.desc_les;
    setQuestion(updt);
  };
  const initialiser = () => {
    document.getElementById("reset_form").click();
    setchoix(null);
    setaffichageRep(null);
    setIto([]);
    setidLF(null);
    setFormValues([]);
    setQuestion(vide);
    setImage(null);
    setError(vide);
    console.log("click");
  };

  const labelMod = (element) => {
    let lab = options.filter((el) => el.value == element.id_mod);
    return <span className="badge bg-cyan text-wrap">{lab[0]?.label}</span>;
  };
  // const labelCat = (element) => {
  //   let cat = element.split(",").filter(Boolean)
  //   let lab = categoptions.filter((el) => el.value == element.);
  //   return <span className="badge bg-cyan text-wrap">{lab[0]?.label}</span>;
  // };


  // calls whenever state will be change , another approach
  useEffect(() => {
    getAllModule();
  }, []);

  useEffect(() => {
    getAllQuestionnaire(pagination.limit, pagination.offset);
  }, [pagination, eventy]);



  return (
    <>
      <LoadingBar ref={ref} color={barcolor} />
      <div className="container-lg container-fluid py-4">
        <div
          className="modal modal-blur"
          id="modal-danger"
          aria-modal="true"
          role="dialog"
        >
          <div
            className="modal-dialog modal-sm modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="modal-status bg-danger"></div>
              <div className="modal-body text-center py-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon mb-2 text-danger icon-lg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9v2m0 4v.01"></path>
                  <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                </svg>
                <h3>vous etes sur ?</h3>
                <div className="text-muted">
                  vous-voulez vraiment supprimer cet exercice? c'est
                  irreversible.
                </div>
              </div>
              <div className="modal-footer">
                <div className="w-100">
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        id="fermerdel"
                        className="btn w-100"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={supprimer}
                        className={
                          "btn btn-danger w-100 " + (load && "disabled")
                        }
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal modal-blur" id="exampleModal">
          <div
            className="modal-dialog modal-lg"
            style={{ maxWidth: "1000px" }}
            role="document"
          >
            {choix === null ? (
              <div className="my-3">
                <button
                  type="reset"
                  className="p-0 m-0"
                  style={{ visibility: "hidden" }}
                  id="reset_form"
                ></button>
                <div className="modal-content">
                  <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                    <h2 className="p-2">Choix de type Questionnaire</h2>
                    <button
                      className="btn-close p-0"
                      type="button"
                      data-bs-dismiss="modal"
                      id="fermerModal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div
                    className="modal-body py-0 pb-3"
                    style={{ overflowY: "auto" }}
                  >
                    <div>
                      <div className="d-flex gap-2 h-100 align-items-center justify-content-center my-5">
                        <button
                          className="btn btn-cyan"
                          type="button"
                          onClick={() => setchoix("qcm")}
                        >
                          Question à choix multiple
                        </button>
                        <button
                          className="btn btn-cyan"
                          type="button"
                          onClick={() => setchoix("question")}
                        >
                          Question réponse
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <div className="w-100">
                      <div className="row justify-content-between">
                        <div className={"col d-flex justify-content-end"}>
                          <button
                            type="button"
                            className="btn"
                            data-bs-dismiss="modal"
                          >
                            <FontAwesomeIcon icon={faCancel} className="me-2" />
                            Quitter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : choix === "question" ? (
              <form className="my-3" onSubmit={handleSubmit}>
                <button
                  type="reset"
                  className="p-0 m-0"
                  style={{ visibility: "hidden" }}
                  id="reset_form"
                ></button>
                <div className="modal-content">
                  <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                    <h2 className="p-2">Formulaire Questionnaire</h2>
                    <button
                      className="btn-close p-0"
                      type="button"
                      data-bs-dismiss="modal"
                      id="fermerModal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body py-0 pb-3"
                    style={{ height: "400px", overflowY: "auto" }}
                  >
                    <div className="my-3">
                      <label className="form-label">Question</label>
                      <CKEditor
                        editor={Editor}
                        data={formValues.qcm_ques}
                        onSubmit={() => {
                          if (
                            formValues.qcm_ques === "" ||
                            formValues.qcm_ques === undefined ||
                            formValues.qcm_ques === null
                          ) {
                            setError({
                              ...error,
                              qcm_ques: "ce champs est requis",
                            });
                          }
                        }}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log("ato");
                          // console.log( { event, editor, data } );
                          console.log(data);
                          if (data === "") {
                            setError({
                              ...error,
                              qcm_ques: "la question est réquise !",
                            });
                          } else {
                            setQuestion({ ...question, qcm_ques: data });
                            setError({ ...error, qcm_ques: null });
                          }
                        }}
                      />
                      {error.qcm_ques && (
                        <p className="text-danger">{error.qcm_ques}</p>
                      )}
                      {/* <input
                        className={
                          "form-control " + (error.qcm_ques && "is-invalid")
                        }
                        type="text"
                        name="qcm_ques"
                        value={lesson.qcm_ques}
                        onChange={handleChange}
                        placeholder="Saisir le question du questionnaire"
                        required
                      />
                      {error.qcm_ques && (
                        <p className="invalid-feedback">{error.qcm_ques}</p>
                      )} */}
                    </div>
                    <div className="my-3">
                      <label className="form-label">
                        Durée du question (en secondes)
                      </label>
                      <input
                        className={
                          "form-control " + (error.dure_ques && "is-invalid")
                        }
                        type="text"
                        name="dure_ques"
                        value={question.dure_ques}
                        onChange={handleChange}
                        placeholder="Durée du questionnaire"
                        required
                      />
                      {error.dure_ques && (
                        <p className="invalid-feedback">{error.dure_ques}</p>
                      )}
                    </div>
                    <div className="my-3 row">
                      <div className="col">
                        <label className="form-label">Modules</label>
                        <Select
                          closeMenuOnSelect
                          components={animatedComponents}
                          defaultValue={[options[4], options[5]]}
                          name="id_mod"
                          placeholder="choix module"
                          options={options}
                          value={ito}
                          className="is-invalid"
                          onChange={(choice) => change(choice)}
                          noOptionsMessage={() => "plus d'options"}
                        />
                        {error.id_mod && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {error.id_mod}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-lg-6 my-1">
                        <label className="form-label">Langue</label>
                        <Select
                          closeMenuOnSelect
                          components={animatedComponents}
                          defaultValue={[Langue[0]]}
                          isMulti={false}
                          name="lang_ques"
                          value={lg}
                          placeholder="choix langue"
                          options={Langue}
                          onChange={(choice) => {
                            setLg(choice);
                            setQuestion({
                              ...question,
                              lang_ques: choice.value,
                            });
                            setError({ ...error, lang_ques: null });
                          }}
                        />
                        {error?.lang_ques && (
                          <p className="text-danger fs-5">{error?.lang_ques}</p>
                        )}
                      </div>
                      <div className="col-6 my-1">
                        <label className="form-label">catégories</label>

                        <div className="" style={{ height: "38px" }}>
                          <SelectFinal
                            options={categoptions}
                            load={false}
                            value={"value"}
                            label={"label"}
                            choix={""}
                            placeholder="choix catégories"
                            setFormValues={setQuestion}
                            setError={setError}
                            name={"id_categ"}
                          />
                          {error?.id_categ && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {error?.id_categ}{" "}
                          </div>
                        )}
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      <label className="form-label">Réponse</label>
                      <input
                        className={
                          "form-control " + (error.val_ques && "is-invalid")
                        }
                        type="number"
                        name="val_ques"
                        value={question.val_ques}
                        onChange={handleChange}
                        placeholder="Saisir la réponse du questionnaire"
                        required
                      />
                      {error.val_ques && (
                        <p className="invalid-feedback">{error.val_ques}</p>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="w-100">
                      <div className="row justify-content-between">
                        <div className={"col d-flex justify-content-start"}>
                          <button
                            type="button"
                            className="btn me-2"
                            onClick={() => initialiser()}
                          >
                            <FontAwesomeIcon
                              icon={faStepBackward}
                              className="me-2"
                            />{" "}
                            Retour au choix
                          </button>
                          <button
                            type="button"
                            className="btn"
                            data-bs-dismiss="modal"
                          >
                            <FontAwesomeIcon icon={faCancel} className="me-2" />{" "}
                            Quitter
                          </button>
                        </div>
                        <div className="col d-flex justify-content-end">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary " + (load && "disabled")
                            }
                          >
                            <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              choix === "qcm" && (
                <form
                  className="my-3"
                  onSubmit={idLF == null ? handleSubmit : ajoutLessonFichier}
                >
                  <button
                    type="reset"
                    className="p-0 m-0"
                    style={{ visibility: "hidden" }}
                    id="reset_form"
                  ></button>
                  <div className="modal-content">
                    <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                      <h2 className="p-2">Formulaire Questionnaire</h2>
                      <button
                        className="btn-close p-0"
                        type="button"
                        data-bs-dismiss="modal"
                        id="fermerModal"
                        aria-label="Close"
                      ></button>
                    </div>

                    {idLF == null ? (
                      <div
                        className="modal-body py-0 pb-3"
                        style={{ height: "400px", overflowY: "auto" }}
                      >
                        <div className="my-3">
                          <label className="form-label">Question du qcm</label>
                          <CKEditor
                            editor={Editor}
                            data={formValues.qcm_ques}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log("ato");
                              // console.log( { event, editor, data } );
                              console.log(data);
                              if (data === "") {
                                setError({
                                  ...error,
                                  qcm_ques: "la question du qcm est réquise !",
                                });
                              } else {
                                setQuestion({ ...question, qcm_ques: data });
                                setError({ ...error, qcm_ques: null });
                              }
                            }}
                          />
                          {error.qcm_ques && (
                            <p className="text-danger">{error.qcm_ques}</p>
                          )}
                          {/* <input
                            className={
                              "form-control " + (error.qcm_ques && "is-invalid")
                            }
                            type="text"
                            name="qcm_ques"
                            value={lesson.qcm_ques}
                            onChange={handleChange}
                            placeholder="Saisir le question du questionnaire"
                            required
                          />
                          {error.qcm_ques && (
                            <p className="invalid-feedback">{error.qcm_ques}</p>
                          )} */}
                        </div>
                        <div className="my-3">
                          <label className="form-label">
                            Durée du qcm (en secondes)
                          </label>
                          <input
                            className={
                              "form-control " +
                              (error.dure_ques && "is-invalid")
                            }
                            type="text"
                            name="dure_ques"
                            value={question.dure_ques}
                            onChange={handleChange}
                            placeholder="Durée du questionnaire"
                            required
                          />
                          {error.dure_ques && (
                            <p className="invalid-feedback">
                              {error.dure_ques}
                            </p>
                          )}
                        </div>
                        <div className="my-3 row">
                          <div className="col">
                            <label className="form-label">Modules</label>
                            <Select
                              closeMenuOnSelect
                              components={animatedComponents}
                              defaultValue={[options[4], options[5]]}
                              name="id_mod"
                              placeholder="choix module"
                              options={options}
                              value={ito}
                              className="is-invalid"
                              onChange={(choice) => change(choice)}
                              noOptionsMessage={() => "plus d'options"}
                            />
                            {error.id_mod && (
                              <div style={{ color: "red", fontSize: "12px" }}>
                                {error.id_mod}{" "}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="my-3 row">
                          <div className="col-lg-6 my-1">
                            <label className="form-label">Langue</label>
                            <Select
                              closeMenuOnSelect
                              components={animatedComponents}
                              defaultValue={[Langue[0]]}
                              isMulti={false}
                              name="lang_ques"
                              value={lg}
                              placeholder="choix langue"
                              options={Langue}
                              onChange={(choice) => {
                                setLg(choice);
                                setQuestion({
                                  ...question,
                                  lang_ques: choice.value,
                                });
                                setError({ ...error, lang_ques: null });
                              }}
                            />
                            {error.lang_ques && (
                              <p className="text-danger fs-5">
                                {error.lang_ques}
                              </p>
                            )}
                          </div>
                          <div className="col-lg-6 my-1">
                            <label className="form-label">catégories</label>

                            <div className="" style={{ height: "38px" }}>
                              <SelectFinal
                                options={categoptions}
                                load={false}
                                value={"value"}
                                label={"label"}
                                choix={""}
                                placeholder="choix catégories"
                                setFormValues={setQuestion}
                                setError={setError}
                                name={"id_categ"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-1">
                          <label className="form-label">Type de réponse</label>
                          <select
                            className="form-control"
                            value={affichageRep}
                            id="type_reponse"
                            placeholder="choisir..."
                            onChange={(e) => setaffichageRep(e.target.value)}
                          >
                            <option>choisir ici...</option>
                            <option value="texte">Texte</option>
                            <option value="image">Image</option>
                          </select>
                          <div id="" style={{ color: "red" }}></div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="modal-body py-0 pb-3"
                        style={{ height: "550px", overflowY: "auto" }}
                      >
                        <p className="lead my-2">
                          Ajouter ici les réponses liées aux exercices , sinon
                          cliquer sur le bouton{" "}
                          <span className="fw-bold text-primary">Terminer</span>
                        </p>
                        {affichageRep === "texte" ? (
                          <div className="mt-1">
                            <div className="row my-3">
                              <div className="col d-flex align-items-center gap-3">
                                <label className="form-label">
                                  choix de réponse
                                </label>{" "}
                                <button
                                  className="btn btn-primary"
                                  onClick={addInput}
                                >
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    className="pe-2"
                                  />
                                  Ajouter un champs
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              {formValues.map((element, index) => (
                                <div
                                  className="col-6 d-flex gap-2 my-2 align-items-stretch"
                                  key={index}
                                >
                                  <div className="card p-3 w-100">
                                    <div className="mt-1">
                                      <label className="form-label">
                                        C'est la vrai réponse ?
                                      </label>
                                      <select
                                        className="form-control"
                                        name="type_rep"
                                        placeholder="choisir..."
                                        onChange={(e) =>
                                          handleChangeFichier(index, e)
                                        }
                                      >
                                        <option>choisir ici...</option>
                                        <option value={1}>oui</option>
                                        <option value={0}>non</option>
                                      </select>
                                      <div
                                        style={{ color: "red" }}
                                        id={`type_rep${index}`}
                                      ></div>
                                    </div>
                                    <div className="my-3">
                                      <label className="form-label">
                                        Description de la réponse
                                      </label>
                                      <textarea
                                        value={element.desc_rep}
                                        rows="5"
                                        className="form-control"
                                        name="desc_rep"
                                        onChange={(e) =>
                                          handleChangeFichier(index, e)
                                        }
                                      ></textarea>
                                      <div
                                        style={{ color: "red" }}
                                        id={`desc_rep${index}`}
                                      ></div>
                                    </div>

                                    {index ? (
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="mt-1">
                            <div className="row my-3">
                              <div className="col d-flex align-items-center gap-3">
                                <label className="form-label">
                                  Fichier rattaché
                                </label>{" "}
                                <button
                                  className="btn btn-primary"
                                  onClick={addInput}
                                >
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    className="pe-2"
                                  />
                                  Ajouter un champs
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              {formValues.map((element, index) => (
                                <div
                                  className="col-6 d-flex gap-2 my-2"
                                  key={index}
                                >
                                  <div className="card p-3 w-100">
                                    <div className="mt-1">
                                      <label className="form-label">
                                        C'est la vrai réponse ?
                                      </label>
                                      <select
                                        className="form-control"
                                        name="type_repq"
                                        placeholder="choisir..."
                                        onChange={(e) =>
                                          handleChangeFichier(index, e)
                                        }
                                      >
                                        <option>choisir ici...</option>
                                        <option value={1}>oui</option>
                                        <option value={0}>non</option>
                                      </select>
                                      <div
                                        style={{ color: "red" }}
                                        id={`type_repq${index}`}
                                      ></div>
                                    </div>
                                    <div className="mt-3">
                                      <label className="form-label">
                                        Fichier du reponse
                                      </label>
                                      <input
                                        type="file"
                                        name="fichierq"
                                        className="form-control"
                                        id={`fichierqq${index}`}
                                        onChange={(e) =>
                                          onChangefichier(index, e)
                                        }
                                      />
                                      <div
                                        style={{ color: "red" }}
                                        id={`fichierq${index}`}
                                      ></div>
                                    </div>
                                    <div className="mt-3">
                                      <label className="form-label">
                                        Description du reponse
                                      </label>
                                      <textarea
                                        value={element.desc_repq}
                                        className="form-control"
                                        name="desc_repq"
                                        onChange={(e) =>
                                          handleChangeFichier(index, e)
                                        }
                                      ></textarea>
                                      <div
                                        style={{ color: "red" }}
                                        id={`desc_repq${index}`}
                                      ></div>
                                    </div>

                                    {/* <div className="my-3">
                                  <label className="form-label">
                                    Type du fichier
                                  </label>
                                  <select
                                    className="form-control"
                                    value={element.type_fichq}
                                    name="type_fichq"
                                    id="type_fichq"
                                    onChange={(e) =>
                                      handleChangeFichier(index, e)
                                    }
                                    required
                                  >
                                    <option>choisir ici...</option>
                                    <option value="Photo">Photo</option>
                                    <option value="Video">Video</option>
                                    <option value="Audio">Audio</option>
                                    <option value="document">Document</option>
                                    <option value="Autre">Autre</option>
                                  </select>
                                  <div
                                    style={{ color: "red" }}
                                    id={`type_fichq${index}`}
                                  ></div>
                                </div> */}
                                    {index ? (
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => removeFormFields(index)}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="modal-footer">
                      <div className="w-100">
                        <div className="row justify-content-between">
                          <div className={"col d-flex justify-content-start"}>
                            {idLF == null ? (
                              <>
                                <button
                                  type="button"
                                  className="btn me-2"
                                  onClick={() => initialiser()}
                                >
                                  <FontAwesomeIcon
                                    icon={faStepBackward}
                                    className="me-2"
                                  />{" "}
                                  Retour au choix
                                </button>
                                <button
                                  type="button"
                                  className="btn"
                                  data-bs-dismiss="modal"
                                >
                                  <FontAwesomeIcon
                                    icon={faCancel}
                                    className="me-2"
                                  />{" "}
                                  Annuler
                                </button>
                              </>
                            ) : (
                              <button
                                type="button"
                                className="btn"
                                data-bs-dismiss="modal"
                              >
                                <FontAwesomeIcon
                                  icon={faCancel}
                                  className="me-2"
                                />
                                Terminer
                              </button>
                            )}
                          </div>
                          <div className="col d-flex justify-content-end">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary " + (load && "disabled")
                              }
                            >
                              <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                              {idLF == null ? <>Suivant</> : <>Ajouter</>}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )
            )}
          </div>
        </div>
        <div className="modal modal-blur" id="modif">
          <div
            className="modal-dialog modal-lg"
            style={{ maxWidth: "1000px" }}
            role="document"
          >
            <form className="my-3" onSubmit={handleUpdate}>
              <div className="modal-content">
                <div className="d-flex flex-row justify-content-between align-items-center p-2 border-bottom border-4 border-black">
                  <h2 className="p-2">Formulaire </h2>
                  <button
                    className="btn-close p-0"
                    type="button"
                    data-bs-dismiss="modal"
                    id="fermermodif"
                    aria-label="Close"
                  ></button>
                </div>

                <div
                  className="modal-body py-0 pb-3"
                  style={{ height: "550px", overflowY: "auto" }}
                >
                  <div
                    className="modal-body py-0 pb-3"
                    style={{ height: "400px", overflowY: "auto" }}
                  >
                    <div className="my-3">
                      <label className="form-label">Question du qcm</label>
                      <CKEditor
                        editor={Editor}
                        data={formValues.qcm_ques}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log("ato");
                          // console.log( { event, editor, data } );
                          console.log(data);
                          if (data === "") {
                            setError({
                              ...error,
                              qcm_ques: "la question du qcm est réquise !",
                            });
                          } else {
                            setQuestion({ ...question, qcm_ques: data });
                            setError({ ...error, qcm_ques: null });
                          }
                        }}
                      />
                      {error.qcm_ques && (
                        <p className="text-danger">{error.qcm_ques}</p>
                      )}
                      {/* <input
                            className={
                              "form-control " + (error.qcm_ques && "is-invalid")
                            }
                            type="text"
                            name="qcm_ques"
                            value={lesson.qcm_ques}
                            onChange={handleChange}
                            placeholder="Saisir le question du questionnaire"
                            required
                          />
                          {error.qcm_ques && (
                            <p className="invalid-feedback">{error.qcm_ques}</p>
                          )} */}
                    </div>
                    <div className="my-3">
                      <label className="form-label">
                        Durée du qcm (en secondes)
                      </label>
                      <input
                        className={
                          "form-control " + (error.dure_ques && "is-invalid")
                        }
                        type="text"
                        name="dure_ques"
                        value={question.dure_ques}
                        onChange={handleChange}
                        placeholder="Durée du questionnaire"
                        required
                      />
                      {error.dure_ques && (
                        <p className="invalid-feedback">{error.dure_ques}</p>
                      )}
                    </div>
                    <div className="my-3 row">
                      <div className="col">
                        <label className="form-label">Modules</label>
                        <Select
                          closeMenuOnSelect
                          components={animatedComponents}
                          defaultValue={[options[4], options[5]]}
                          name="id_mod"
                          placeholder="choix module"
                          options={options}
                          value={ito}
                          className="is-invalid"
                          onChange={(choice) => change(choice)}
                          noOptionsMessage={() => "plus d'options"}
                        />
                        {error.id_mod && (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {error.id_mod}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="w-100">
                    <div className="row justify-content-between">
                      <div className={"col d-flex justify-content-start"}>
                        <button
                          type="button"
                          className="btn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <FontAwesomeIcon icon={faCancel} className="me-2" />{" "}
                          Annuler
                        </button>
                      </div>
                      <div className="col d-flex justify-content-end">
                        <button
                          type="submit"
                          className={"btn btn-primary " + (load && "disabled")}
                        >
                          <FontAwesomeIcon icon={faAdd} className="me-2" />
                          Modifier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between mb-3">
          <div>
            <h2>Exercices</h2>
          </div>
          <div>
            <button
              type="button"
              onClick={() => initialiser()}
              className="btn btn-primary px-4 py-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <FontAwesomeIcon icon={faAdd} className="me-2" /> Nouvel exercice
            </button>
          </div>
        </div>
        <DataTable
          title={<h4 className="">Liste des exercices</h4>}
          columns={columns}
          data={questionnaire.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )}
          pagination
          paginationServer
          paginationTotalRows={totalRows} // Total des lignes pour calculer le nombre de pages
          onChangePage={handlePageChange} // Gestion du changement de page
          onChangeRowsPerPage={handlePerRowsChange} // Gestion du changement de lignes par page
        />
        <div className="overflow-auto">
          {/* <table className="table table-hover table-bordered table-striped table-light ">
            <thead>
              <tr className="">
                <th>ID</th>
                <th>Question</th>
                <th className="col-1">Durée (en séconde)</th>
                <th className="col-1">Module</th>
                <th className="col-1">Réponses</th>
                <th className="col-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedInvoices.sort((a,b)=>  new Date(b.updated_at)-new Date(a.updated_at)).map((element, index) => (
                <tr key={index}>
                  <td>{element.id_ques}</td>
                  <td> <div className="non-edit">
              <CKEditor
              config={editorConfig}
                editor={Editor}
                data={element.qcm_ques}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                  editor.enableReadOnlyMode("editor");
                  const toolbar = editor.ui.view.toolbar.element;
                  toolbar.style.display = "none";
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                }}
          
              />
              </div></td>
                  <td>{element.dure_ques}</td>
                  <td>{labelMod(element)}</td>

                  <td className="text-center">
                    {element.val_ques ? (
                      <>{element.val_ques}</>
                    ) : (
                      <div className="d-flex">
                        <Link
                          to={`/admin/reponses/${element.id_ques}`}
                          className="nav-link "
                        >
                          <span className="badge bg-info py-2 px-2 mx-2">
                            <FontAwesomeIcon icon={faFile} className="me-2" />
                            Texte
                          </span>
                        </Link>
                        <Link
                          to={`/admin/reponsesFichier/${element.id_ques}`}
                          className="nav-link "
                        >
                          <span className="badge bg-info py-2 px-2 mx-2">
                            <FontAwesomeIcon icon={faFile} className="me-2" />
                            image
                          </span>
                        </Link>
                      </div>
                    )}
                  </td>
                  <td className="d-flex gap-2 justify-content-center">
              
                    <Modifquestionnaire exo={element} load={getAllQuestionnaire} type={element.val_ques ? 'reponse' : 'choix'}/>
                    <button
                      type="button"
                      onClick={() => setID(element.id_ques)}
                      data-bs-toggle="modal"
                      data-bs-target="#modal-danger"
                      className="btn btn-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { generateRandomString } from "../RandomString";
import { sortByProperty } from "./SortByProperty";

export default function SelectFinal({
  setFormValues,
  setError,
  name,
  choix,
  options,
  label,
  seteventy,
  max
}) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);

  // Met à jour les valeurs sélectionnées et gère les erreurs
  const handleChange = (selected) => {
    const concatenated = selected?.join(",") || "";
    setFormValues((prev) => ({ ...prev, [name]: concatenated }));
    setError((prev) => ({ ...prev, [name]: null }));
    if (seteventy) seteventy(generateRandomString(5));
  };

  // Synchronise les valeurs sélectionnées avec `choix`
  useEffect(() => {
if (choix) {
  
  const choixArray = choix?.map((item) => item.id_categ); // Récupère les id_categ
  console.log(choixArray)
  setSelectedValues(choixArray);

  if (choix.length > 0) {
    const ids = choixArray?.map(Number);
    const filteredOptions = options.filter((item) => ids.includes(item.value));
    setDefaultOptions(filteredOptions);
  }
}
  }, [choix, options]);

  // Applique les changements à chaque modification des valeurs sélectionnées
  useEffect(() => {
    handleChange(selectedValues);
  }, [selectedValues]);

  return (
    <MultiSelect
      style={{ minWidth: "100px" }}
      display="comma"
      appendTo="self"
      value={selectedValues?.map(Number) || []} // Assure que les valeurs sont bien au format attendu
      onChange={(e) => setSelectedValues(e.value)}
      options={sortByProperty(options)}
      optionValue="value" // S'assure que la clé est bien "value"
      optionLabel={label}
      showClear
      filter
      
      placeholder="Sélectionnez des catégories"
      maxSelectedLabels={max || 7}
      className="w-full md:w-20rem h-100 align-items-center"
      
    />
  );
}

import {
  faAdd,
  faEdit,
  faTrash,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCategoryOptions } from "../../../outils/Categorieopt";
import OnlineSvg from "./Online test-rafiki.svg";
import SelectFinal from "../../../service/Select/selectFinal";
import SelectCustom from "../../../service/Select/selectCustom";

export default function GererNote({ urlAPI,titre,id }) {
  const [showmodal, setshowmodal] = useState({ del: false, form: false });
  const categoptions = useCategoryOptions();
  const [choix,setchoix] = useState("")
  const [selectedRow, setSelectedRows] = useState({});
  const [mode, setmode] = useState(false);
  const [error, setError] = useState({});
  const [formValues, setFormValues] = useState({});
  const [data, setdata] = useState([]);
  const [load, setload] = useState({
    button: false,
    data: true,
  });
  const MonCompte = useSelector((state) => state.compte);
  const abortController = new AbortController();

  const columns = [
    {
      name: "Catégories",
      selector: (row)=> row.categories.map((el,i)=><Badge  key={i}>{el.categ}</Badge>),
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row)=> row.nombre,
      sortable: true,
    },

    {
      width: "175px",
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-2">
          <Button
            className="bg-cyan"
            onClick={() => {
              // Filtrer les photos et garder les autres propriétés

              // Mettre à jour les états
              setmode(true);
              setError({});
              setSelectedRows(row);
              setchoix(row.categories)

              setFormValues(row); // Mettre à jour le formulaire sans les photos
              handleShowModal("form");
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            className="bg-danger"
            onClick={() => {
              setSelectedRows(row);
              handleShowModal("del");
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleShowModal = (mod) => {
    setshowmodal({ ...showmodal, [mod]: !showmodal[mod] });
  };

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;

    setFormValues({ ...formValues, [name]: value });
    setError({ ...error, [name]: null });
  };

  const getdata = () => {
    setload({ ...load, data: true });
    axios
      .get(urlAPI, {
        signal: abortController.signal,
      })
      .then((rep) => {
        console.log(rep.data);
        setdata(rep.data.data);
        setload({ ...load, data: false });
      })
      .catch((err) => {
        setload({ ...load, data: false });
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    formValues.id_org = MonCompte.id_org;
    const apiErrors = {};
    setload({ ...load, button: true });
    console.log(formValues);
    axios
      .post(urlAPI, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((rep) => {
        toast.success("opération réussi !");
        setload({ ...load, button: false });
        handleShowModal("form");
        getdata();
      })
      .catch((error) => {
        console.log(error);
        const msg = error.response.data;
        setload({ ...load, button: false });
        setload(false);
        if (msg) {
          toast.error("Verifier votre saisi !");
          let champs = Object.keys(msg);
          for (let i = 0; i < champs.length; i++) {
            const element = champs[i];
            if (msg[element]) {
              apiErrors[element] = msg[element];
            }
          }
        }
        if (error.response.status == 500) {
          toast.error(error.response.data.message);
        }
        if (error.response.status == 401) {
          toast.info(error.response.data.message);
        }
        setError(apiErrors);
      });
  };
  const handleUpdate = async () => {
    const apiErrors = {};

    setload({ ...load, button: true });
    console.log(formValues);
    await axios
      .post(urlAPI + `/${formValues[id]}?_method=PUT`, formValues, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((rep) => {
        toast.success("opération réussi !");
        setload({ ...load, button: false });
        handleShowModal("form");
        getdata();
      })
      .catch((error) => {
        console.log(formValues);
        console.log(error);
        const msg = error.response.data;
        setload({ ...load, button: false });
        setload(false);
        if (msg) {
          toast.error("Verifier votre saisi !");
          let champs = Object.keys(msg);
          for (let i = 0; i < champs.length; i++) {
            const element = champs[i];
            if (msg[element]) {
              apiErrors[element] = msg[element];
            }
          }
        }
        if (error.response.status == 500) {
          toast.error(error.response.data.message);
        }
        if (error.response.status == 401) {
          toast.info(error.response.data.message);
        }
        setError(apiErrors);
      });
  };

  const handledelete = async () => {
    setload({ ...load, button: true });
    try {
      axios
        .delete(urlAPI + "/" + selectedRow[id])
        .then(() => {
          toast.success("Element supprimé");
          getdata();
          setload({ ...load, button: false });
          handleShowModal("del");
        })
        .catch((e) => {
          console.log(e);
          toast.error("Un erreur est survenu !");
          handleShowModal("del");
          setload({ ...load, button: false });
        });
    } catch (error) {
      toast.error("Un erreur est survenu !");
      handleShowModal("del");
      setload({ ...load, button: false });
    }
  };

  useEffect(() => {
    getdata();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(()=>{
    console.log(urlAPI)
    console.log(formValues)
  },[formValues])

  useEffect(()=>{
    console.log(urlAPI)
  },[])
  return (
    <div>
      <Modal
        show={showmodal.del}
        size="sm"
        onHide={() => handleShowModal("del")}
      >
        <Modal.Header>
          <FontAwesomeIcon icon={faWarning} className="bg-danger me-2" />
          Supprimer
        </Modal.Header>
        <Modal.Body>Vous êtes sur de vouloir supprimer ?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button variant="secondary" onClick={() => handleShowModal("del")}>
              Annuler
            </Button>
            <Button
              variant="danger"
              disabled={load.button}
              onClick={() => handledelete()}
            >
              oui, je confirme
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showmodal.form} size="sm" animation>
        <Modal.Body>
          <form>
            <div className="my-3">
              <label className="form-label">Catégorie</label>
                 <div className="" style={{ height: "38px" }}>
                                        <SelectCustom
                                          options={categoptions}
                                          load={false}
                                          value={"value"}
                                          label={"label"}
                                          choix={choix || ""}
                                          placeholder="choix catégories"
                                          setFormValues={setFormValues}
                                          setError={setError}
                                          name={"id_categ"}
                                          mult={false}
                                        />
                                        {/* {error.id_categ[0] && (
                                        <div style={{ color: "red", fontSize: "12px" }}>
                                          {error.id_categ[0]}{" "}
                                        </div>
                                      )} */}
                                      </div>
            </div>
            <div className="my-3">
              <label className="form-label">Nombre</label>
              <input
                className={
                  "form-control " + (error.nombre?.length > 0 && "is-invalid")
                }
                name="nombre"
                value={formValues.nombre}
                onChange={handleChange}
                type="number"
                placeholder="Saisir le nombre de questionnaire"
              />
              {error.nombre?.length > 0 && (
                <div className="invalid-feedback">{error.nombre[0]}</div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button variant="secondary" onClick={() => handleShowModal("form")}>
              Annuler
            </Button>
            <Button
              variant="primary"
              disabled={load.button}
              onClick={() => (mode ? handleUpdate() : handleSubmit())}
            >
              {mode ? "Modifier" : "Enregistrer"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="container-lg container-fluid py-3">
        <div className="card">
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-4 d-none d-md-block d-lg-block d-flex justify-content-center align-items-center">
                <h3 className="text-center fw-bold">
                 {titre}
                </h3>
                <img
                  src={OnlineSvg}
                  alt="test online"
                  className="mx-auto d-block"
                />
              </div>
              <div className="col-7" style={{ minWidth: "320px" }}>
                <div className="card">
                  <div className="card-body">
                    <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setmode(false);
setchoix(null)
                            setFormValues({});
                            setError({});
                            handleShowModal("form");
                          }}
                        >
                          <FontAwesomeIcon icon={faAdd} className="me-2" />
                          Ajouter
                        </Button>
                      </div>
                    </div>
                    <div className="">
                      <DataTable
                        className="custom-table"
                        columns={columns}
                        data={data}
                        progressPending={load.data}
                        progressComponent={
                          <div className="w-100 d-flex justify-content-center py-5">
                            <TailSpin
                              height="80"
                              width="80"
                              color="#22577A"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </div>
                        }
                        contextMessage={{
                          singular: "élement",
                          plural: "élements",
                          message: "sélectionnées",
                        }}
                        pagination
                        paginationPerPage={5}
                        paginationComponentOptions={{
                          rowsPerPageText: "Lignes par page",
                        }}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const IpAdresse = 'permis-conduire-mg.com'
export const IpAdresseReactApp ='127.0.0.1'
export const lien_vers_reseautage = `http://${IpAdresseReactApp}:3001`
export const adresse = `http://${IpAdresse}`
export const API_URL = `${adresse}:8080/api/`;
export const file_url =`${adresse}:8080/storage/`;
export const Register_API = API_URL + "auth/register"
export const LOGIN_API = API_URL + "auth/login"
export const ResendEmail_API = API_URL +"auth/resendEmailToken/"
export const LOGOUT = API_URL + "auth/logout" 
export const CATEGORIE_API = API_URL + "categorie"
export const CategorieByModule_API = API_URL + "categorieByModule/"
export const moduleByLesson = API_URL + "moduleByLesson/"
export const moduleByCategorie = API_URL + "moduleByCategorie/"
export const MODULE_API = API_URL + "modules"  
export const PROFILE_API = API_URL + "auth/profile" 
export const LESSON_API = API_URL + "lessons"
export const FichierLesson_API = API_URL +"fichierl"
export const ModuleCategorie_API = API_URL +"modulecategorie"
export const ListmoduleHateaos =API_URL+"moduleByCategorieHateaos/"
export const list4moduleHateaosByLangue =API_URL+"moduleByLangueHateaosLimit4/"
export const listallmodule=API_URL+"moduleByLangueHateaos/"
export const Questionnaire_API = API_URL +"questionnaires"
export const Questionnaire_API_V2 = API_URL + "v2/questionnaires"
export const Reponse_API = API_URL +"reponses"
export const ListReponse_API=API_URL+"reponse/questionnaire/"
export const ListFichierq_API=API_URL+"fichierq/questionnaire"
export const Fichierq_API = API_URL +"fichierq"
export const Contact_API = API_URL +"contact"
export const commentmodule_API = API_URL + "commentairemodules"
export const commentlesson_API = API_URL + "commentairelessons"
export const lessonByModulesHateaos_API = API_URL+"lessonByModulesHateaos"
export const ListLessonHateaos_API = API_URL+"moduleByLessonHateaos"
export const AvisModule_API = API_URL+"avismodules"
export const AvisLesson_API = API_URL+"avislessons"
export const dixquestion_API = API_URL+ "dixQuestionnaresHateaos"
export const SearchModuleLesson_API=API_URL+"modulelessons"
export const modulebylanguecateg_API=API_URL+"moduleByCategorieByLangueHateaos/"
export const modulequestionrep =API_URL+"moduleQuestionaireReponseHateaos"
export const cursus_API = API_URL+"cursus"
export const Pourcentage_API=API_URL+"pourcentages"
export const TexteLois_API = API_URL+"textetlois"
export const FichierLois_API =API_URL+"fichiert"
export const ACTU_API =API_URL+"actualites"
export const SENSI_API=API_URL+"sensibilisations"
export const TypeLois_API=API_URL+"typelois"
export const loisByType_API =API_URL+"loisByType"
export const reponseContact_API =API_URL+"repcontact"
export const reponseByContact =API_URL+"repbycontact"
export const notificationCli_API =API_URL+"notifications"
export const organisme_API =API_URL+"organismes"
export const membre_API =API_URL+"membres"
export const candidat_API =API_URL+"candidats"

export const moduleWithRatting =API_URL + "moduleWithRatting"
export const moduleWithRattingLessonQuestion = API_URL +"moduleWithRattingLessonQuestion"

export const lessonWithRatting =API_URL + "lessonWithRatting"
export const typeExam = API_URL + "typeexamens"
export const randomQuestionnairesbyModule = API_URL+"randomQuestionnairesWithTimeByModule?id_mod="
export const randomQuestionnairesbyCategorie = API_URL +"randomQuestionnairesWithTimebyCategorie?id_categ="
export const questionnaireById = API_URL + "detailsQuestionnaire/"

export const getListeUtilisateurs_Api = API_URL+"getListeUtilisateurs"
export const getNombreInscrits = API_URL +"getNombreInscrits"
export const ChangerMDP_API = API_URL+"account/changeMdp"
export const mdpOublierByEmail = API_URL +"account/mdpOublierByEmail"
export const motsministres = API_URL +"motsministres"
export const chapitres =API_URL +"chapitres"
export const chapitresByLesson = API_URL + "chapitreByLesson/"
export const activeUser = API_URL +"activeUser/"
export const lessonByModule = API_URL +"lessonByModule/"
export const paramNotes  = API_URL + "paramnotes"
export const paramtheo = API_URL + "parametrenotetheorique"
export const paramprat = API_URL + "parametrenotepratique"
export const paramexo = API_URL + "parametrenoteexercice"
export const Historique_api = API_URL + "historique"
export const UserEndpoint = API_URL + "users"
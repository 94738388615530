import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { generateRandomString } from "../RandomString";
import { sortByProperty } from "./SortByProperty";

export default function SelectCustom({
  setFormValues,
  setError,
  name,
  choix,
  options,
  label,
  seteventy,
  max
}) {
  const [selectedValue, setSelectedValue] = useState(null); // Une seule valeur
  const [defaultOptions, setDefaultOptions] = useState([]);

  // Met à jour la valeur sélectionnée et gère les erreurs
  const handleChange = (selected) => {
    setFormValues((prev) => ({ ...prev, [name]: selected || "" }));
    setError((prev) => ({ ...prev, [name]: null }));
    if (seteventy) seteventy(generateRandomString(5));
  };

  // Synchronise la valeur sélectionnée avec `choix`
  useEffect(() => {
    if (choix) {
      const choixArray = choix?.map((item) => item.id_categ); // Récupère les id_categ
      console.log(choixArray);
      if (choixArray.length > 0) {
        setSelectedValue(Number(choixArray[0])); // Prend la première valeur
        const ids = choixArray?.map(Number);
        const filteredOptions = options.filter((item) => ids.includes(item.value));
        setDefaultOptions(filteredOptions);
      }
    }
  }, [choix, options]);

  // Applique les changements à chaque modification de la valeur sélectionnée
  useEffect(() => {
    handleChange(selectedValue);
  }, [selectedValue]);

  return (
    <Dropdown
      style={{ minWidth: "100px" }}
      appendTo="self"
      value={selectedValue} // Une seule valeur sélectionnée
      onChange={(e) => setSelectedValue(e.value)} // Met à jour la valeur sélectionnée
      options={sortByProperty(options)}
      optionValue="value" // Utilise la clé "value"
      optionLabel={label} // Utilise l'étiquette pour l'affichage
      placeholder="Sélectionnez une catégorie"
      className="w-full md:w-20rem h-100 align-items-center"
    />
  );
}
